import axios from '@/libs/api.request'
// 用户名密码登录
export const loginByPwd = (data) => {
    return axios.request({
        url: '/store/member/login',
        data,
        method: 'post'
    })
}
// 获取手机验证码
export const getloginCode = (data) => {
    return axios.request({
        url: '/store/member/login/sms/send',
        data,
        method: 'post'
    })
}
// 修改密码
// 获取手机验证码
export const getChangeCode = (data) => {
    return axios.request({
        url: '/store/member/change/sms/send',
        data,
        method: 'post'
    })
}
export const vailConfirm = (data) => {
    return axios.request({
        url: '/store/member/change/sms',
        data,
        method: 'post'
    })
}
export const memberChange = (data) => {
    return axios.request({
        url: '/store/member/change',
        data,
        method: 'post'
    })
}
// /store/member/change
// /store/member/change/sms
// /store/member/change/sms/send
// 手机验证码登录
export const loginByVld = (data) => {
    return axios.request({
        url: '/store/member/login/sms',
        data,
        method: 'post'
    })
}
// 退出登录
export const loginOut= (data) => {
    return axios.request({
        url: '/store/member/logout',
        method: 'post'
    })
}

// 找回密码
// 获取手机验证码
export const getRetrieveCode = (data) => {
    return axios.request({
        url: '/store/member/retrieve/sms/send',
        data,
        method: 'post'
    })
}
// 校验手机接口
export const retrieveByVld = (data) => {
    return axios.request({
        url: '/store/member/retrieve/sms',
        data,
        method: 'post'
    })
}
// 修改密码
export const changePwd = (data) => {
    return axios.request({
        url: '/store/member/retrieve',
        data,
        method: 'post'
    })
}

// 子用户
// 列表接口
export const getSubuserList = (page) => {
    return axios.request({
        url: `/store/submember/list/${page}`,
        method: 'get'
    })
}
// 获取手机验证码
export const getSubmemberCode = (data) => {
    return axios.request({
        url: '/store/submember/add/sms/send',
        data,
        method: 'post'
    })
}
//校验手机验证码
export const smsSubmemberCode = (data) => {
    return axios.request({
        url: '/store/submember/add/sms',
        data,
        method: 'post'
    })
}
// 校验密码
export const validatePwd = (data) => {
    return axios.request({
        url: '/store/submember/add',
        data,
        method: 'post'
    })
}
// 修改密码
export const updatePwd = (data) => {
    return axios.request({
        url: '/store/submember/change/password',
        data,
        method: 'post'
    })
}
// 统计接口
export const storeDetails = () => {
    return axios.request({
        url: '/store/details',
        method: 'get'
    })
}
// /store/details
// /store/submember/change/password
// /store/submember/add
// /store/submember/add/sms
// /store/submember/add/sms/send
// /store/submember/list/{page}
// /store/member/retrieve
// /store/member/retrieve/sms
// /store/member/retrieve/sms/send
// /store/member/login/sms/send
// /store/member/logout
